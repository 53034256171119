import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const PrimeReactOverrides = createGlobalStyle`
    /* Общие стили для инпутов */
    .p-inputtext,
    .p-dropdown-label,
    .p-calendar .p-inputtext {
        padding: ${theme.spacing.medium};
        font-size: ${theme.fontSizes.medium};
        width: 100%;
    }

    /* Стили для выпадающих панелей */
    .p-dropdown-panel,
    .p-calendar-panel,
    .p-datepicker,
    .p-column-filter-overlay {
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.secondary};
        border-radius: ${theme.borderRadius};
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        padding: ${theme.spacing.medium};
    }

    /* Стили для календаря */
    .p-datepicker {
        .p-datepicker-header {
            background-color: ${theme.colors.light};
            border-bottom: 1px solid ${theme.colors.secondary};
            padding: ${theme.spacing.small} 0;
            margin-bottom: ${theme.spacing.small};
        }

        .p-datepicker-calendar {
            margin: ${theme.spacing.small} 0;

            th {
                padding: ${theme.spacing.small};
                color: ${theme.colors.secondary};
            }

            td {
                padding: 0;

                > span,
                > button {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 0.2s, color 0.2s;

                    &:hover,
                    &.p-highlight {
                        background-color: ${theme.colors.primary};
                        color: ${theme.colors.white};
                    }
                }

                .p-datepicker-today > span {
                    background-color: ${theme.colors.light};
                }
            }
        }
    }

    /* Стили для выпадающего списка */
    .p-dropdown-item {
        padding: ${theme.spacing.medium};
        color: ${theme.colors.dark};
        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${theme.colors.light};
        }
    }

    /* Стили для таблицы */
    .p-datatable {
        .p-datatable-header {
            background-color: ${theme.colors.light};
            padding: ${theme.spacing.medium};
        }

        .p-datatable-thead > tr > th {
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
            padding: ${theme.spacing.medium};
        }

        .p-datatable-tbody > tr > td {
            padding: ${theme.spacing.medium};
        }
    }

    /* Стили для кнопок фильтра */
    .p-column-filter-add-button,
    .p-column-filter-remove-button {
        background-color: ${theme.colors.secondary};
        border: none;
        color: ${theme.colors.white};
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        border-radius: ${theme.borderRadius};
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${theme.colors.primary};
        }
    }
    
        /* Стили для кнопок фильтра */
    .p-column-filter-buttonbar {
        display: flex;
        justify-content: flex-end;
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        background-color: ${theme.colors.light};
        border-top: 1px solid ${theme.colors.secondary};
    }

    .p-column-filter-buttonbar .p-button {
        margin-left: ${theme.spacing.small};
        font-size: ${theme.fontSizes.small};
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        border-radius: ${theme.borderRadius};
        transition: all 0.3s ease;
    }

    .p-column-filter-buttonbar .p-button:first-child {
        margin-left: 0;
    }

    .p-column-filter-buttonbar .p-button.p-button-outlined {
        background-color: transparent;
        border: 1px solid ${theme.colors.secondary};
        color: ${theme.colors.secondary};

        &:hover {
            background-color: ${theme.colors.secondary};
            color: ${theme.colors.white};
        }
    }

    .p-column-filter-buttonbar .p-button.p-button-primary {
        background-color: ${theme.colors.primary};
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.white};

        &:hover {
            background-color: ${theme.colors.primaryDark};
            border-color: ${theme.colors.primaryDark};
        }
    }

    /* Стили для меню фильтра */
    .p-column-filter-overlay {
        padding: 0;
        border: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .p-column-filter-overlay-menu {
        padding: ${theme.spacing.medium};
    }

    .p-column-filter-constraint {
        padding: ${theme.spacing.small} 0;
        border-bottom: 1px solid ${theme.colors.light};

        &:last-child {
            border-bottom: none;
        }
    }

    .p-column-filter-matchmode-dropdown {
        margin-bottom: ${theme.spacing.small};
    }

    .p-column-filter-value {
        margin-top: ${theme.spacing.small};
    }
    
        /* Стили для выпадающих меню фильтров */
    .p-column-filter-overlay {
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.secondary};
        border-radius: ${theme.borderRadius};
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 0;
        min-width: 200px;
        max-width: 300px;
    }

    .p-column-filter-row {
        padding: ${theme.spacing.small};
    }

    .p-column-filter-row-items {
        padding: 0;
    }

    .p-column-filter-row-item {
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        margin: 0;
        border-bottom: 1px solid ${theme.colors.light};

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: ${theme.colors.light};
        }
    }

    .p-column-filter-operator {
        padding: ${theme.spacing.small};
        border-bottom: 1px solid ${theme.colors.light};
    }

    .p-column-filter-constraints {
        padding: ${theme.spacing.small};
    }

    .p-column-filter-add-rule {
        padding: ${theme.spacing.small};
    }

    .p-column-filter-buttonbar {
        padding: ${theme.spacing.small};
        background-color: ${theme.colors.light};
        border-top: 1px solid ${theme.colors.secondary};
    }

    .p-column-filter-buttonbar .p-button {
        font-size: ${theme.fontSizes.small};
        padding: ${theme.spacing.xsmall} ${theme.spacing.small};
        margin-right: ${theme.spacing.xsmall};

        &:last-child {
            margin-right: 0;
        }
    }

    .p-column-filter-menu-button {
        width: 2rem;
        height: 2rem;
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.secondary};
        border-radius: ${theme.borderRadius};

        &:hover {
            background-color: ${theme.colors.light};
        }

        &.p-column-filter-menu-button-open {
            background-color: ${theme.colors.light};
            border-color: ${theme.colors.primary};
            color: ${theme.colors.primary};
        }
    }

    /* Стили для инпутов внутри фильтров */
    .p-column-filter .p-inputtext,
    .p-column-filter .p-dropdown,
    .p-column-filter .p-multiselect {
        width: 100%;
        font-size: ${theme.fontSizes.small};
        padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    }

    /* Стили для выпадающих списков */
    .p-dropdown-panel {
        border: 1px solid ${theme.colors.secondary};
        border-radius: ${theme.borderRadius};
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .p-dropdown-items {
        padding: 0;
    }

    .p-dropdown-item {
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        font-size: ${theme.fontSizes.small};

        &:hover {
            background-color: ${theme.colors.light};
        }
    }
`;
