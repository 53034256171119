const API_BASE_URL = `${process.env.REACT_APP_API_URL}/x5/auctions`;
const ATI_BASE_URL = `${process.env.REACT_APP_API_URL}/ati/active_firms`;
const FIRM_INFO_BASE_URL = `${process.env.REACT_APP_API_URL}/ati/firm`;

export interface RoutePoint {
    pointID: string;
    sequence: number;
    pointType: string;
    pointPlace: string;
    pointAddress: string;
    timeZoneName: string;
    timeZoneMillis: number;
    arrivalTimeMillis: number;
}

export interface Auction {
    id: number;
    auction_id: number;
    invoice_id: number;
    type: string;
    created_time: string;
    end_time_utc: string;
    start_rate: number;
    rate_step: number;
    best_bid_kopecks: number | null;
    current_trc_bid: number | null;
    comment: string | null;
    route_points: RoutePoint[];
    body_type: string;
    required_tonnage: number;
    creator_credentials: string;
    customer_name: string | null;
    temperature: string;
    cargo_type: string;
    is_tail_lift: boolean;
    required_pallets: number;
    created_at: string;
    updated_at: string;
}

export interface ActiveFirm {
    id: number;
    firm_name: string;
    invoice_id: number;
    sequence: number;
    actual_trucks_count: number;
    ati_id: number;
    search_count: number;
    sum_count: number;
    trucks_count: number;
    created_at: string;
    updated_at: string;
}

export interface FirmInfo {
    ati_id: number;
    firm_name: string;
    ownership: string;
    full_name: string;
    firm_type: string;
    firm_type_id_int: number;
    fias_id: string;
    address: string;
    info_reference: string;
    passport_reference: string;
    score: number;
    inn: string;
    last_month_active_days: number;
}

export const getAuctions = async (): Promise<Auction[]> => {
    const response = await fetch(API_BASE_URL);
    if (!response.ok) {
        throw new Error('Failed to fetch auctions');
    }
    return response.json();
};

export const getAuctionById = async (invoice_id: number): Promise<Auction> => {
    const response = await fetch(`${API_BASE_URL}/${invoice_id}`);
    if (!response.ok) {
        throw new Error('Failed to fetch auction');
    }
    return response.json();
};

export const getActiveFirmsByInvoiceId = async (invoice_id: number): Promise<ActiveFirm[]> => {
    const response = await fetch(`${ATI_BASE_URL}/${invoice_id}`);
    if (!response.ok) {
        throw new Error('Failed to fetch active firms');
    }
    return response.json();
};

export const getFirmInfoByAtiId = async (ati_id: number): Promise<FirmInfo> => {
    const response = await fetch(`${FIRM_INFO_BASE_URL}/${ati_id}`);
    if (!response.ok) {
        throw new Error('Failed to fetch firm info');
    }
    return response.json();
};
