import React, { useEffect, useState } from 'react';
import { getAuctions, Auction } from '../services/auctionService';
import styled from 'styled-components';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { theme } from '../styles/theme';

const DashboardWrapper = styled.div`
  padding: ${theme.spacing.large};
`;

const DashboardTitle = styled.h1`
    color: ${theme.colors.primary};
    margin-bottom: ${theme.spacing.large};
    font-size: ${theme.fontSizes.xlarge};
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: ${theme.spacing.medium};
  margin-bottom: ${theme.spacing.xlarge};
`;

const StatsCard = styled(Card)`
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.boxShadow};
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.medium};
`;

const CardTitle = styled.h3`
  color: ${theme.colors.secondary};
  margin-bottom: ${theme.spacing.small};
  font-size: ${theme.fontSizes.medium};
`;

const CardValue = styled.p`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.xlarge};
  font-weight: bold;
  margin: 0;
`;

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: ${theme.spacing.large};

  .chart-card {
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.boxShadow};
    padding: ${theme.spacing.large};
  }
`;

const Dashboard: React.FC = () => {
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getAuctions();
                setAuctions(data);
            } catch (error) {
                console.error('Failed to fetch auctions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const getTotalAuctions = () => auctions.length;

    const getActiveAuctions = () => auctions.filter(auction => new Date(auction.end_time_utc) > new Date()).length;

    const getTotalValue = () => auctions.reduce((sum, auction) => sum + (auction.current_trc_bid || 0), 0) / 100;

    const getAverageStartRate = () => {
        const sum = auctions.reduce((sum, auction) => sum + auction.start_rate, 0);
        return (sum / auctions.length / 100).toFixed(2);
    };

    const getAuctionsByType = () => {
        const typeCount: { [key: string]: number } = {};
        auctions.forEach(auction => {
            typeCount[auction.type] = (typeCount[auction.type] || 0) + 1;
        });
        return {
            labels: Object.keys(typeCount),
            datasets: [{
                data: Object.values(typeCount),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
            }]
        };
    };

    const getAuctionsByMonth = () => {
        const monthCount: { [key: string]: number } = {};
        auctions.forEach(auction => {
            const month = new Date(auction.created_time).toLocaleString('default', { month: 'long' });
            monthCount[month] = (monthCount[month] || 0) + 1;
        });
        return {
            labels: Object.keys(monthCount),
            datasets: [{
                label: 'Количество аукционов',
                data: Object.values(monthCount),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            }]
        };
    };

    if (loading) {
        return <p className="loading">Загрузка данных...</p>;
    }

    return (
        <DashboardWrapper>
            <DashboardTitle>Дашборд аукционов</DashboardTitle>
            <StatsGrid>
                <StatsCard>
                    <CardContent>
                        <CardTitle>Всего аукционов</CardTitle>
                        <CardValue>{getTotalAuctions()}</CardValue>
                    </CardContent>
                </StatsCard>
                <StatsCard>
                    <CardContent>
                        <CardTitle>Активные аукционы</CardTitle>
                        <CardValue>{getActiveAuctions()}</CardValue>
                    </CardContent>
                </StatsCard>
                <StatsCard>
                    <CardContent>
                        <CardTitle>Общая стоимость</CardTitle>
                        <CardValue>{getTotalValue().toFixed(2)} ₽</CardValue>
                    </CardContent>
                </StatsCard>
                <StatsCard>
                    <CardContent>
                        <CardTitle>Средняя начальная ставка</CardTitle>
                        <CardValue>{getAverageStartRate()} ₽</CardValue>
                    </CardContent>
                </StatsCard>
            </StatsGrid>
            <ChartWrapper>
                <div className="chart-card">
                    <h3>Аукционы по типам</h3>
                    <Chart type="pie" data={getAuctionsByType()} />
                </div>
                <div className="chart-card">
                    <h3>Аукционы по месяцам</h3>
                    <Chart type="bar" data={getAuctionsByMonth()} />
                </div>
            </ChartWrapper>
        </DashboardWrapper>
    );
};

export default Dashboard;
