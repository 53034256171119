import React from 'react';
import styled from 'styled-components';
import { Auction } from '../services/auctionService';
import { theme } from '../styles/theme';

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.medium};
`;

const AuctionTitle = styled.h1`
    font-size: ${theme.fontSizes.xlarge};
    color: ${theme.colors.primary};
    margin: 0;
`;

interface AuctionStatusProps {
    isActive: boolean;
}

const AuctionStatus = styled.span<AuctionStatusProps>`
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
    border-radius: ${theme.borderRadius};
    background-color: ${(props) => props.isActive ? theme.colors.success : theme.colors.warning};
    color: ${theme.colors.white};
`;

interface AuctionHeaderProps {
    auction: Auction;
}

const AuctionHeader: React.FC<AuctionHeaderProps> = ({ auction }) => {
    const getAuctionStatus = (): { text: string; isActive: boolean } => {
        const now = new Date();
        const endTime = new Date(auction.end_time_utc);
        return now > endTime ? { text: 'Завершен', isActive: false } : { text: 'Активен', isActive: true };
    };

    const status = getAuctionStatus();

    return (
        <HeaderWrapper>
            <AuctionTitle>Аукцион #{auction.auction_id}</AuctionTitle>
            <AuctionStatus isActive={status.isActive}>{status.text}</AuctionStatus>
        </HeaderWrapper>
    );
};

export default React.memo(AuctionHeader);
