import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirmInfoByAtiId, type FirmInfo } from '../services/auctionService';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import { Button } from './Button';
import { ProgressSpinner } from 'primereact/progressspinner';

const FirmInfoWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing.large};
`;

const SectionTitle = styled.h2`
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.primary};
    margin-top: ${theme.spacing.xlarge};
    margin-bottom: ${theme.spacing.medium};
    border-bottom: 2px solid ${theme.colors.primary};
    padding-bottom: ${theme.spacing.small};
`;

const Section = styled.section`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.boxShadow};
    padding: ${theme.spacing.large};
    margin-bottom: ${theme.spacing.large};
`;

const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: ${theme.spacing.medium};
`;

const InfoItem = styled.div`
    background-color: ${theme.colors.light};
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.medium};
`;

const InfoLabel = styled.h3`
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.secondary};
    margin-bottom: ${theme.spacing.small};
`;

const InfoValue = styled.p`
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.dark};
    font-weight: bold;
    margin: 0;
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: ${theme.spacing.medium};
    margin-top: ${theme.spacing.large};
`;

const FirmInfoComponent: React.FC = () => {
    const { ati_id } = useParams<{ ati_id: string }>();
    const [firmInfo, setFirmInfo] = useState<FirmInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (ati_id) {
                    const data = await getFirmInfoByAtiId(parseInt(ati_id));
                    setFirmInfo(data);
                }
            } catch (error) {
                console.error(`Failed to fetch firm info with ati_id ${ati_id}:`, error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [ati_id]);

    if (loading) {
        return (
            <LoadingWrapper>
                <ProgressSpinner />
            </LoadingWrapper>
        );
    }

    if (!firmInfo) {
        return <p>Информация о фирме не найдена</p>;
    }

    return (
        <FirmInfoWrapper>
            <Section>
                <SectionTitle>{firmInfo.firm_name}</SectionTitle>
                <InfoGrid>
                    <InfoItem>
                        <InfoLabel>Полное название</InfoLabel>
                        <InfoValue>{firmInfo.full_name}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabel>Вид собственности</InfoLabel>
                        <InfoValue>{firmInfo.ownership}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabel>Тип фирмы</InfoLabel>
                        <InfoValue>{firmInfo.firm_type}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabel>ИНН</InfoLabel>
                        <InfoValue>{firmInfo.inn}</InfoValue>
                    </InfoItem>
                </InfoGrid>
            </Section>

            <Section>
                <SectionTitle>Контактная информация</SectionTitle>
                <InfoItem>
                    <InfoLabel>Адрес</InfoLabel>
                    <InfoValue>{firmInfo.address}</InfoValue>
                </InfoItem>
            </Section>

            <Section>
                <SectionTitle>Статистика</SectionTitle>
                <InfoGrid>
                    <InfoItem>
                        <InfoLabel>Баллы</InfoLabel>
                        <InfoValue>{firmInfo.score}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabel>Активные дни за последний месяц</InfoLabel>
                        <InfoValue>{firmInfo.last_month_active_days}</InfoValue>
                    </InfoItem>
                </InfoGrid>
            </Section>

            <ButtonContainer>
                <Button
                    variant="primary"
                    icon="pi-info-circle"
                    onClick={() => window.open(firmInfo.info_reference, '_blank', 'noopener,noreferrer')}
                >
                    Информация
                </Button>

                <Button
                    variant="primary"
                    icon="pi-file"
                    onClick={() => window.open(firmInfo.passport_reference, '_blank', 'noopener,noreferrer')}
                >
                    Паспорт
                </Button>

            </ButtonContainer>
        </FirmInfoWrapper>
    );
};

export default FirmInfoComponent;
