import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${theme.fonts.main};
    background-color: ${theme.colors.background};
    color: ${theme.colors.dark};
    font-size: ${theme.fontSizes.medium};
    line-height: 1.6;
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.primary};
    margin-top: 0;
  }

  .p-component {
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.medium};
  }

  .p-datatable {
    .p-datatable-header {
      background-color: ${theme.colors.light};
      color: ${theme.colors.dark};
      border-radius: ${theme.borderRadius};
      padding: ${theme.spacing.medium};
    }

    .p-datatable-thead > tr > th {
      background-color: ${theme.colors.light};
      color: ${theme.colors.dark};
      padding: ${theme.spacing.medium};
      border-width: 0 0 1px 0;
    }

    .p-datatable-tbody > tr > td {
      padding: ${theme.spacing.medium};
    }

    .p-datatable-tbody > tr:nth-child(even) {
      background-color: ${theme.colors.background};
    }

      .p-paginator {
          .p-paginator-page,
          .p-paginator-next,
          .p-paginator-last,
          .p-paginator-first,
          .p-paginator-prev {
              min-width: 2.5rem;
              height: 2.5rem;
              margin: 0.2rem;
              font-size: ${theme.fontSizes.medium};
          }

          .p-paginator-current {
              font-size: ${theme.fontSizes.medium};
          }

          .p-dropdown {
              height: 2.5rem;
              font-size: ${theme.fontSizes.medium};
          }
      }

      .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
          font-size: ${theme.fontSizes.medium};
          padding: 0.75rem 1rem;
      }

  }

  .p-button {
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};

    &:enabled:hover {
      background-color: ${theme.colors.info};
      border-color: ${theme.colors.info};
    }
  }

  .p-inputtext {
    padding: ${theme.spacing.small} ${theme.spacing.medium};
  }

  .p-button.p-button-icon-only {
      width: 2rem;
      height: 2rem;
  }

  .p-button .p-button-icon {
      font-size: 1rem;
  }

  .pi {
      font-size: 1.2rem;
  }

`;

export default GlobalStyle;
