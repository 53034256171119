// src/hooks/useAuctionData.ts
import { useState, useEffect } from 'react';
import { getAuctionById, getActiveFirmsByInvoiceId, Auction, ActiveFirm } from '../services/auctionService';

interface AuctionData {
    auction: Auction | null;
    activeFirms: ActiveFirm[];
    loading: boolean;
    error: string | null;
}

export const useAuctionData = (invoiceId: string | undefined): AuctionData => {
    const [auctionData, setAuctionData] = useState<AuctionData>({
        auction: null,
        activeFirms: [],
        loading: true,
        error: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!invoiceId) {
                setAuctionData(prev => ({ ...prev, loading: false, error: 'Invalid invoice ID' }));
                return;
            }

            try {
                const [auctionResult, activeFirmsResult] = await Promise.all([
                    getAuctionById(parseInt(invoiceId)),
                    getActiveFirmsByInvoiceId(parseInt(invoiceId))
                ]);

                setAuctionData({
                    auction: auctionResult,
                    activeFirms: activeFirmsResult,
                    loading: false,
                    error: null,
                });
            } catch (error) {
                console.error(`Failed to fetch data for invoice_id ${invoiceId}:`, error);
                setAuctionData(prev => ({
                    ...prev,
                    loading: false,
                    error: 'Failed to load auction data. Please try again later.',
                }));
            }
        };

        fetchData();
    }, [invoiceId]);

    return auctionData;
};
