import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAuctionData } from '../hooks/useAuctionData';
import AuctionHeader from './AuctionHeader';
import AuctionInfo from './AuctionInfo';
import { theme } from '../styles/theme';

const RouteTimeline = lazy(() => import('./RouteTimeline'));
const ActiveFirmsTable = lazy(() => import('./ActiveFirmsTable'));
const BidChart = lazy(() => import('./BidChart'));

const AuctionDetailWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing.large};
`;

const SectionTitle = styled.h2`
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.primary};
  margin-top: ${theme.spacing.xlarge};
  margin-bottom: ${theme.spacing.medium};
  border-bottom: 2px solid ${theme.colors.primary};
  padding-bottom: ${theme.spacing.small};
`;

const Section = styled.section`
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.boxShadow};
  padding: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.large};
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const ErrorMessage = styled.p`
  color: ${theme.colors.danger};
  text-align: center;
  font-size: ${theme.fontSizes.large};
  margin-top: ${theme.spacing.xlarge};
`;

const AuctionDetail: React.FC = () => {
    const { invoice_id } = useParams<{ invoice_id: string }>();
    const { auction, activeFirms, loading, error } = useAuctionData(invoice_id);

    if (loading) {
        return (
            <LoadingWrapper>
                <ProgressSpinner />
            </LoadingWrapper>
        );
    }

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (!auction) {
        return <ErrorMessage>Аукцион не найден</ErrorMessage>;
    }

    return (
        <AuctionDetailWrapper>
            <Section>
                <AuctionHeader auction={auction} />
            </Section>

            <Section>
                <SectionTitle>Информация об аукционе</SectionTitle>
                <AuctionInfo auction={auction} />
            </Section>

            <Section>
                <SectionTitle>Маршрут</SectionTitle>
                <Suspense fallback={<LoadingWrapper><ProgressSpinner /></LoadingWrapper>}>
                    <RouteTimeline routePoints={auction.route_points} />
                </Suspense>
            </Section>

            <Section>
                <SectionTitle>Активные фирмы</SectionTitle>
                <Suspense fallback={<LoadingWrapper><ProgressSpinner /></LoadingWrapper>}>
                    <ActiveFirmsTable activeFirms={activeFirms} />
                </Suspense>
            </Section>

            <Section>
                <SectionTitle>График ставок</SectionTitle>
                <Suspense fallback={<LoadingWrapper><ProgressSpinner /></LoadingWrapper>}>
                    <BidChart auction={auction} />
                </Suspense>
            </Section>

            <Button
                variant="secondary"
                icon="pi-arrow-left"
                onClick={() => window.history.back()}
            >
                Вернуться к списку аукционов
            </Button>

        </AuctionDetailWrapper>
    );
};

export default AuctionDetail;
