export const theme = {
    colors: {
        primary: '#6a8caf',
        primaryLight: '#8ba5c4',
        primaryDark: '#4a6d90',
        secondary: '#9cb4cc',
        secondaryLight: '#b1c5d9',
        secondaryDark: '#7a96b3',
        success: '#a3c9a8',
        danger: '#f0a3a3',
        warning: '#f8d486',
        info: '#87ceeb',
        light: '#f5f5f5',
        dark: '#4a5568',
        white: '#ffffff',
        background: '#f8f9fa',
        sidebarBg: '#e2e8f0',
        sidebarText: '#4a5568',
        borderColor: '#e2e8f0',
    },
    fonts: {
        main: "'Roboto', Arial, sans-serif",
        secondary: "'Open Sans', sans-serif",
    },
    fontSizes: {
        xsmall: '0.75rem',
        small: '0.875rem',
        medium: '1rem',
        large: '1.25rem',
        xlarge: '1.5rem',
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    spacing: {
        xxsmall: '0.25rem',
        xsmall: '0.5rem',
        small: '0.75rem',
        medium: '1rem',
        large: '1.5rem',
        xlarge: '2rem',
        xxlarge: '3rem',
    },
    borderRadius: {
        small: '0.25rem',
        medium: '0.5rem',
        large: '1rem',
    },
    boxShadow: {
        small: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        medium: '0 4px 6px rgba(0, 0, 0, 0.1)',
        large: '0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.1)',
    },
    transitions: {
        quick: '0.15s ease-in-out',
        medium: '0.3s ease-in-out',
        slow: '0.5s ease-in-out',
    },
    zIndex: {
        dropdown: 1000,
        sticky: 1020,
        fixed: 1030,
        modalBackdrop: 1040,
        modal: 1050,
        popover: 1060,
        tooltip: 1070,
    },
    button: {
        padding: {
            small: '0.5rem 1rem',
            medium: '0.75rem 1.5rem',
            large: '1rem 2rem',
        },
        fontSize: {
            small: '0.875rem',
            medium: '1rem',
            large: '1.25rem',
        },
        fontWeight: 600,
        borderRadius: '0.25rem',
        transition: '0.3s ease',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

        primary: {
            background: '#6a8caf',
            color: '#ffffff',
            hoverBackground: '#4a6d90',
        },
        secondary: {
            background: '#9cb4cc',
            color: '#ffffff',
            hoverBackground: '#7a96b3',
        },
        success: {
            background: '#a3c9a8',
            color: '#ffffff',
            hoverBackground: '#82b389',
        },
        danger: {
            background: '#f0a3a3',
            color: '#ffffff',
            hoverBackground: '#e78787',
        },
    },
};
