import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { theme } from '../styles/theme';

const LayoutWrapper = styled.div`
    display: flex;
`;

const Sidebar = styled.nav<{ isOpen: boolean }>`
    width: ${props => props.isOpen ? '250px' : '60px'};
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${theme.colors.sidebarBg};
    transition: width 0.3s ease;
    overflow-x: hidden;
    z-index: 1000;
    box-shadow: ${theme.boxShadow};

    @media (max-width: 768px) {
        width: ${props => props.isOpen ? '250px' : '0'};
        left: ${props => props.isOpen ? '0' : '-60px'};
    }
`;

const SidebarContent = styled.div`
    padding: ${theme.spacing.large} 0;
    color: ${theme.colors.sidebarText};
`;

const Logo = styled.div`
    text-align: center;
    padding: 0 ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.large};
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
`;

const NavItem = styled(Link)<{ active: boolean }>`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.medium};
    color: ${props => props.active ? theme.colors.primary : theme.colors.sidebarText};
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(106, 140, 175, 0.1);
    }

    i {
        margin-right: ${theme.spacing.medium};
        font-size: 1.2em;
    }
`;

const NavText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MainContent = styled.main<{ sidebarIsOpen: boolean }>`
    flex: 1;
    margin-left: ${props => props.sidebarIsOpen ? '250px' : '60px'};
    padding: ${theme.spacing.large};
    transition: margin-left 0.3s ease;
    background-color: ${theme.colors.background};

    @media (max-width: 768px) {
        margin-left: 0;
        padding-top: 60px;
    }
`;

const ToggleButton = styled.button`
    position: fixed;
    top: ${theme.spacing.medium};
    left: ${theme.spacing.medium};
    z-index: 1001;
    background: none;
    border: none;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.large};
    cursor: pointer;
    padding: ${theme.spacing.small};
    border-radius: ${theme.borderRadius};
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(106, 140, 175, 0.1);
    }
`;

const Overlay = styled.div<{ isVisible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: ${props => props.isVisible ? 'block' : 'none'};

    @media (min-width: 769px) {
        display: none;
    }
`;

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <LayoutWrapper>
            <Sidebar isOpen={isSidebarOpen}>
                <SidebarContent>
                    <Logo>{isSidebarOpen ? 'X5 Auctions' : 'X5'}</Logo>
                    <NavItem to="/" active={location.pathname === "/"}>
                        <i className="pi pi-home" />
                        {isSidebarOpen && <NavText>Дашборд</NavText>}
                    </NavItem>
                    <NavItem to="/auctions" active={location.pathname === "/auctions"}>
                        <i className="pi pi-list" />
                        {isSidebarOpen && <NavText>Список аукционов</NavText>}
                    </NavItem>
                </SidebarContent>
            </Sidebar>
            <ToggleButton onClick={toggleSidebar}>
                <i className={`pi ${isSidebarOpen ? 'pi-times' : 'pi-bars'}`} />
            </ToggleButton>
            <Overlay isVisible={isSidebarOpen} onClick={toggleSidebar} />
            <MainContent sidebarIsOpen={isSidebarOpen}>{children}</MainContent>
        </LayoutWrapper>
    );
};

export default Layout;
