import React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';

type ButtonVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'outlined';

interface ButtonProps {
    variant?: ButtonVariant;
    size?: 'small' | 'medium' | 'large';
    icon?: string;
    onClick?: () => void;
    children?: React.ReactNode;
    label?: string;
    className?: string;
}

const StyledButton = styled.button<{ variant: ButtonVariant; size: 'small' | 'medium' | 'large' }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${props => theme.button.padding[props.size]};
    font-size: ${props => theme.button.fontSize[props.size]};
    font-weight: ${theme.button.fontWeight};
    border-radius: ${theme.button.borderRadius};
    transition: ${theme.button.transition};
    box-shadow: ${theme.button.boxShadow};
    border: none;
    cursor: pointer;
    background-color: ${props => props.variant === 'outlined' ? 'transparent' : theme.button[props.variant].background};
    color: ${props => props.variant === 'outlined' ? theme.button.primary.background : theme.button[props.variant].color};
    border: ${props => props.variant === 'outlined' ? `1px solid ${theme.button.primary.background}` : 'none'};

    &:hover {
        background-color: ${props => props.variant === 'outlined' ? theme.colors.light : theme.button[props.variant].hoverBackground};
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    i {
        margin-right: ${props => props.children ? '0.5rem' : '0'};
        font-size: 1.2em;
    }
`;

export const Button: React.FC<ButtonProps> = ({
                                                  variant = 'primary',
                                                  size = 'medium',
                                                  icon,
                                                  onClick,
                                                  children,
                                                  label,
                                                  className
                                              }) => {
    return (
        <StyledButton variant={variant} size={size} onClick={onClick} className={className}>
            {icon && <i className={`pi ${icon}`}></i>}
            {label || children}
        </StyledButton>
    );
};
