import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import GlobalStyle from './styles/global';
import { PrimeReactOverrides } from './styles/primereact-overrides';
import { theme } from './styles/theme';
import Layout from './components/Layout';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <PrimeReactOverrides />
            <Router>
                <Layout>
                    <AppRouter />
                </Layout>
            </Router>
        </ThemeProvider>
    );
};

export default App;
