import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getAuctions, Auction } from '../services/auctionService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import { Button } from './Button';
import { FilterMatchMode } from 'primereact/api';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { addLocale } from 'primereact/api';
import {InputNumber} from "primereact/inputnumber";

interface Filters {
    global: { value: string | null; matchMode: FilterMatchMode };
    [key: string]: { value: any; matchMode: FilterMatchMode };
}

interface DateFilters {
    startDate: Date | null;
    endDate: Date | null;
}

const StyledDataTable = styled(DataTable)`
  .p-paginator {
    .p-paginator-page {
      &.p-highlight {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }
    }
  }
`;

const setupLocale = () => {
    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['Вск', 'Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        today: 'Сегодня',
        clear: 'Очистить'
    });
};

const AuctionListWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing.large};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.large};

    h1 {
        font-size: ${theme.fontSizes.xlarge};
        color: ${theme.colors.primary};
    }
`;

const StyledCalendar = styled(Calendar)`
    .p-inputtext {
        width: 100%;
        font-size: ${theme.fontSizes.small};
        padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    }
`;

const DateTimeCell = styled.div`
  display: flex;
  flex-direction: column;
  
  .date {
    font-weight: bold;
  }
  
  .time {
    color: ${theme.colors.secondary};
    font-size: ${theme.fontSizes.small};
  }
`;


const FiltersWrapper = styled.div`
    display: flex;
    gap: ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.large};
    flex-wrap: wrap;
`;

const FilterItem = styled.div`
    flex: 1;
    min-width: 200px;
`;

const StyledLink = styled(Link)`
    color: ${theme.colors.primary};
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`;

const dateFilterTemplate = (options: any) => {
    return (
        <Calendar
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            dateFormat="dd.mm.yy"
            placeholder="Выберите дату"
            showTime
            hourFormat="24"
            locale="ru"
        />
    );
};



// Вспомогательные функции
const formatDateTime = (value: string) => {
    const date = new Date(value);
    return (
        <DateTimeCell>
            <span className="date">{format(date, 'dd.MM.yyyy', { locale: ru })}</span>
            <span className="time">{format(date, 'HH:mm', { locale: ru })}</span>
        </DateTimeCell>
    );
};

const AuctionList: React.FC = () => {
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [filters, setFilters] = useState<Filters>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        invoice_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        auction_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        type: { value: null, matchMode: FilterMatchMode.EQUALS },
        start_rate: { value: null, matchMode: FilterMatchMode.EQUALS },
        created_time: { value: null, matchMode: FilterMatchMode.DATE_IS },
        end_time_utc: { value: null, matchMode: FilterMatchMode.DATE_IS },
        current_trc_bid: { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [dateFilters, setDateFilters] = useState<DateFilters>({
        startDate: null,
        endDate: null
    });
    const [typeFilter, setTypeFilter] = useState<string | null>(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event: { first: number; rows: number }) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const fetchAuctions = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getAuctions();
            setAuctions(data);
        } catch (error) {
            console.error('Failed to fetch auctions:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setupLocale();
        fetchAuctions();
    }, [fetchAuctions]);

    const convertToRubles = useCallback((kopecks: number | null): string => {
        return kopecks === null ? 'Нет данных' : `${(kopecks / 100).toFixed(2)} ₽`;
    }, []);

    const getCurrentBidValue = useCallback((rowData: Auction): number => {
        return rowData.current_trc_bid || 0;
    }, []);

    const currentBidBodyTemplate = useCallback((rowData: Auction) => {
        return convertToRubles(rowData.current_trc_bid);
    }, [convertToRubles]);

    const currentBidSortFunction = useCallback((data: Auction[], order: number | null | undefined) => {
        return [...data].sort((a, b) => {
            const bidA = getCurrentBidValue(a);
            const bidB = getCurrentBidValue(b);
            const sortOrder = order === 1 ? 1 : -1;
            return sortOrder * (bidA - bidB);
        });
    }, [getCurrentBidValue]);

    const dateSortFunction = useCallback((data: Auction[], field: keyof Auction, order: number | null | undefined) => {
        return [...data].sort((a, b) => {
            const valueA = new Date(a[field] as string).getTime();
            const valueB = new Date(b[field] as string).getTime();
            const sortOrder = order === 1 ? 1 : -1;
            return sortOrder * (valueA - valueB);
        });
    }, []);

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters(prevFilters => ({
            ...prevFilters,
            global: { value, matchMode: FilterMatchMode.CONTAINS }
        }));
        setGlobalFilterValue(value);
    };

    const onFilterChange = (name: string, value: any) => {
        if (name === 'type') {
            setTypeFilter(value);
            setFilters(prevFilters => ({
                ...prevFilters,
                type: { value, matchMode: FilterMatchMode.EQUALS }
            }));
        } else if (name === 'startDate' || name === 'endDate') {
            setDateFilters(prev => ({ ...prev, [name]: value }));
            setFilters(prevFilters => ({
                ...prevFilters,
                [name === 'startDate' ? 'created_time' : 'end_time_utc']: { value, matchMode: FilterMatchMode.DATE_IS }
            }));
        }
    };

    const filteredAuctions = useMemo(() => {
        return auctions.filter(auction => {
            if (typeFilter && auction.type !== typeFilter) return false;
            if (dateFilters.startDate && new Date(auction.created_time) < dateFilters.startDate) return false;
            if (dateFilters.endDate && new Date(auction.end_time_utc) > dateFilters.endDate) return false;
            return true;
        });
    }, [auctions, typeFilter, dateFilters]);

    const linkBodyTemplate = useCallback((rowData: Auction) => {
        return <StyledLink to={`/auction/${rowData.invoice_id}`}>{rowData.invoice_id}</StyledLink>;
    }, []);

    const statusBodyTemplate = useCallback((rowData: Auction) => {
        const now = new Date();
        const endTime = new Date(rowData.end_time_utc);
        const isActive = now <= endTime;
        return (
            <span style={{
                color: isActive ? theme.colors.success : theme.colors.warning,
                fontWeight: 'bold'
            }}>
        {isActive ? 'Активен' : 'Завершен'}
      </span>
        );
    }, []);

    const getStatusValue = useCallback((rowData: Auction) => {
        const now = new Date();
        const endTime = new Date(rowData.end_time_utc);
        return now <= endTime ? 'Активен' : 'Завершен';
    }, []);

    const statusSortFunction = useCallback((data: Auction[], order: number | null | undefined) => {
        return [...data].sort((a, b) => {
            const statusA = getStatusValue(a);
            const statusB = getStatusValue(b);
            const sortOrder = order === 1 ? 1 : -1;
            return sortOrder * statusA.localeCompare(statusB);
        });
    }, [getStatusValue]);

    const statusFilterTemplate = useCallback((options: any) => {
        return (
            <Dropdown
                value={options.value}
                options={[
                    { label: 'Активен', value: 'Активен' },
                    { label: 'Завершен', value: 'Завершен' }
                ]}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder="Выберите статус"
                className="p-column-filter"
            />
        );
    }, []);

    if (loading) {
        return (
            <LoadingWrapper>
                <ProgressSpinner />
            </LoadingWrapper>
        );
    }

    return (
        <AuctionListWrapper>
            <Header>
                <h1>Список аукционов</h1>
                <Button variant="primary" icon="pi-refresh" onClick={fetchAuctions}>
                    Обновить
                </Button>
            </Header>

            <FiltersWrapper>
                <FilterItem>
                    <InputText
                        className="p-inputtext-lg"
                        placeholder="Глобальный поиск"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        style={{ width: '100%' }}
                    />
                </FilterItem>
                <FilterItem>
                    <Dropdown
                        className="p-dropdown-lg"
                        options={Array.from(new Set(auctions.map(a => a.type)))}
                        value={typeFilter}
                        onChange={(e) => onFilterChange('type', e.value)}
                        placeholder="Тип аукциона"
                        style={{ width: '100%' }}
                    />
                </FilterItem>
                <FilterItem>
                    <StyledCalendar
                        value={dateFilters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.value)}
                        placeholder="Дата начала"
                        showIcon
                    />
                </FilterItem>
                <FilterItem>
                    <StyledCalendar
                        value={dateFilters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.value)}
                        placeholder="Дата окончания"
                        showIcon
                    />
                </FilterItem>
            </FiltersWrapper>

            <StyledDataTable
                value={filteredAuctions}
                paginator
                rows={rows}
                rowsPerPageOptions={[10, 25, 50]}
                first={first}
                onPage={onPageChange}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Показано {first} - {last} из {totalRecords} аукционов"
                paginatorLeft={<div>Страница {Math.floor(first / rows) + 1}</div>}
                emptyMessage="Аукционы не найдены"
                responsiveLayout="scroll"
                dataKey="id"
                className="p-datatable-sm"
            >

                <Column
                    field="invoice_id"
                    header="ID счета"
                    body={linkBodyTemplate}
                    filter
                    filterPlaceholder="Поиск по ID"
                    style={{ width: '150px' }} // Добавлено для контроля ширины колонки
                />
                <Column
                    field="type"
                    header="Тип"
                    filter
                    filterPlaceholder="Поиск по типу"
                    style={{ width: '150px' }}
                />
                <Column field="start_rate" header="Начальная ставка" body={(rowData) => convertToRubles(rowData.start_rate)} filter filterPlaceholder="Поиск по ставке" />
                <Column
                    field="created_time"
                    header="Время создания"
                    body={(rowData) => formatDateTime(rowData.created_time)}
                    sortable
                    sortFunction={(event) => dateSortFunction(event.data, 'created_time', event.order)}
                    filter
                    filterElement={dateFilterTemplate}
                    filterMatchMode="custom"
                    filterFunction={(value, filter) => {
                        if (filter === undefined || filter === null || filter === '') {
                            return true;
                        }
                        const date = new Date(value);
                        const filterDate = new Date(filter);
                        return date.setHours(0, 0, 0, 0) === filterDate.setHours(0, 0, 0, 0);
                    }}
                    style={{ width: '200px' }}
                />
                <Column
                    field="end_time_utc"
                    header="Время завершения"
                    body={(rowData) => formatDateTime(rowData.end_time_utc)}
                    sortable
                    sortFunction={(event) => dateSortFunction(event.data, 'end_time_utc', event.order)}
                    filter
                    filterElement={dateFilterTemplate}
                    filterMatchMode="custom"
                    filterFunction={(value, filter) => {
                        if (filter === undefined || filter === null || filter === '') {
                            return true;
                        }
                        const date = new Date(value);
                        const filterDate = new Date(filter);
                        return date.setHours(0, 0, 0, 0) === filterDate.setHours(0, 0, 0, 0);
                    }}
                    style={{ width: '200px' }}
                />
                <Column
                    field="current_trc_bid"
                    header="Текущая ставка"
                    body={currentBidBodyTemplate}
                    sortable
                    sortFunction={(event) => currentBidSortFunction(event.data, event.order)}
                    filter
                    filterElement={(options) => (
                        <InputNumber
                            value={options.value}
                            onChange={(e) => options.filterCallback(e.value)}
                            mode="currency"
                            currency="RUB"
                            locale="ru-RU"
                        />
                    )}
                    filterFunction={(value, filter) => {
                        if (filter === undefined || filter === null || filter === '') {
                            return true;
                        }
                        const bid = getCurrentBidValue({ current_trc_bid: value } as Auction);
                        return bid >= filter * 100; // Преобразуем введенное значение в копейки
                    }}
                />
                <Column
                    field="status"
                    header="Статус"
                    body={statusBodyTemplate}
                    sortable
                    sortFunction={(event) => statusSortFunction(event.data, event.order)}
                    filter
                    filterElement={statusFilterTemplate}
                    filterFunction={(value, filter) => {
                        if (filter === undefined || filter === null || filter === '') {
                            return true;
                        }
                        return getStatusValue({ end_time_utc: value } as Auction) === filter;
                    }}
                />

            </StyledDataTable>
        </AuctionListWrapper>
    );
};

export default AuctionList;
